import FolderIcon from "@mui/icons-material/Folder";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { GridCheckIcon } from "@mui/x-data-grid-pro";
import axios from "axios";
import clsx from "clsx";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React from "react";
import { setClientID } from "../../../../Redux/Actions/actions.js";
import { fetchCurrentProject } from "../../DashboardComponents/Form/FormQueries/useCurrentProject.jsx";
import { fetchQuestionnaire } from "../../QueryHooks/questionnaire/useQuestionnaire.jsx";
import { getInvalidInputs } from "../../QueryHooks/validate/useInvalidInputs.jsx";
import * as defaultStyles from "../../styles/main-datagrid.module.css";
import { RequestStatusCell } from "../../UniversalDataGridHelper/dataGridComponents.jsx";
import { ServicesPopover } from "../DataGridCustomComponents/clientComponents.jsx";
import "../styles/client-data-grid.css";

dayjs.extend(customParseFormat);

// CLIENT COLUMNS GENERATOR
export function getColumns(
  dispatch,
  navigate,
  setProjectID,
  servicesPopoverProps,
  queryClient,
) {
  return [
    {
      field: "projectName",
      headerName: "Project Name",
      pinnable: true,
      flex: 1.8,
      hideable: true,
      resizable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: defaultStyles.projectNameHeader,
      cellClassName: defaultStyles.projectNameCells,
      disableColumnMenu: true,
    },
    {
      field: "projectType",
      headerName: "Project Type",
      pinnable: false,
      hideable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: defaultStyles.projectTypeHeader,
      cellClassName: defaultStyles.projectTypeCells,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <span>{params.value || "Unknown"}</span>
          </>
        );
      },
    },
    {
      field: "requestStatus",
      headerName: "Status",
      pinnable: false,
      sortable: true,
      hideable: true,
      flex: 1,
      headerClassName: defaultStyles.requestStatusHeader,
      align: "center",
      renderCell: (params) => {
        return <RequestStatusCell params={params} isAdmin={false} />;
      },
      cellClassName: (params) => {
        if (params.value === null) {
          return "";
        }
        return clsx({
          [defaultStyles.requestStatusCells]: true,
          [defaultStyles.inProgress]: params.value === "IP",
          [defaultStyles.incomplete]: params.value === "I",
          [defaultStyles.pending]: params.value === "P",
          [defaultStyles.revise]: params.value === "RV",
          [defaultStyles.completed]: params.value === "C",
        });
      },
      disableColumnMenu: true,
    },
    {
      field: "servicesRequest",
      headerName: "Services",
      flex: 0.6,
      hideable: true,
      pinnable: false,
      sortable: false,
      headerClassName: defaultStyles.servicesHeader,
      align: "center",
      type: "string",
      valueGetter: (params) => {
        return params.value === true ? "Requested" : "Not Requested";
      },
      renderCell: (params) => {
        const { servicesAnchor, currServicesRow, openServicesPopoverHandler } =
          servicesPopoverProps;

        return params.value === "Requested" ? (
          <>
            <Tooltip
              title="Click to view requested services"
              id="services-tooltip"
              classes={{
                tooltip: "dashboard-tooltip",
                popper: "dashboard-tooltip-popper",
                arrow: "dashboard-arrow",
              }}
            >
              <IconButton
                aria-label="services-expand"
                key={`${params.id}-services-expand}`}
                className={defaultStyles.servicesButton}
                id={params.id}
                onClick={openServicesPopoverHandler}
              >
                <GridCheckIcon className={defaultStyles.boolIcon} />
              </IconButton>
            </Tooltip>
            {servicesAnchor && params.row.id === currServicesRow ? (
              <ServicesPopover params={params} />
            ) : null}
          </>
        ) : (
          <Tooltip
            title="No Services Requested"
            id="services-tooltip"
            classes={{
              tooltip: "dashboard-tooltip",
              popper: "dashboard-tooltip-popper",
              arrow: "dashboard-arrow",
            }}
          >
            <HorizontalRuleIcon
              className={defaultStyles.boolIcon}
              id={defaultStyles.hrIcon}
            />
          </Tooltip>
        );
      },
      cellClassName: (params) => {
        return clsx({
          [defaultStyles.boolCol]: true,
          [defaultStyles.requested]: params.value === "Requested",
          [defaultStyles.notRequested]: params.value === "Not Requested",
        });
      },
      disableColumnMenu: true,
    },
    {
      field: "id",
      headerName: "Actions",
      flex: 0.8,
      hideable: true,
      pinnable: true,
      resizable: true,
      headerAlign: "center",
      align: "center",
      headerClassName: defaultStyles.actionHeader,
      disableColumnMenu: true,
      renderCell: (params) => {
        const reqStatus = params.row.requestStatus;
        // Render a button with the label 'View' for rows with requestStatus' equal to 'P' or 'Approved' or 'A'
        if (params.value && (reqStatus === "P" || reqStatus === "RV")) {
          return (
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={
                reqStatus === "RV"
                  ? "reviseButton"
                  : reqStatus === "P"
                    ? "pendingButton"
                    : "archiveButton"
              }
              id={
                reqStatus === "RV"
                  ? "reviseButton"
                  : reqStatus === "P"
                    ? "pendingButton"
                    : "archiveButton"
              }
              onClick={async () => {
                const clientID = params.row.clientId;
                const projectID = params.value;
                dispatch(setProjectID(projectID));
                try {
                  // await queryClient.ensureQueryData({
                  //   queryKey: [
                  //     "currentProject",
                  //     params.row.clientId,
                  //     params.value,
                  //   ],
                  //   queryFn: () =>
                  //     fetchCurrentProject(params.row.clientId, params.value),
                  // });

                  await queryClient.prefetchQuery({
                    queryKey: ["currentProject", clientID, projectID],
                    queryFn: () => fetchCurrentProject(clientID, projectID),
                  });

                  if (reqStatus === "RV") {
                    await queryClient.ensureQueryData({
                      queryKey: ["invalidInputs", clientID, projectID],
                      queryFn: () => getInvalidInputs(clientID, projectID),
                    });
                  }

                  let recentSlide = +params.row.recentSlide;

                  navigate(
                    `/dashboard/form/${clientID}/${projectID}/${recentSlide}`,
                    { replace: true },
                  );
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {reqStatus === "RV" ? "Revise" : "View"}
            </Button>
          );
        } else if (params.value && reqStatus === "IP") {
          // Render a button with the label 'Edit' for rows with requestStatus' equal to 'IP'
          return (
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={"inProgressButton"}
              id={"inProgressButton"}
              onClick={async (e) => {
                //get the id of the row and set value to currentProject in redux store and navigate to '/form/*'
                const projectID = params.value;
                const clientID = params.row.clientId;
                dispatch(setProjectID(projectID));
                await queryClient.prefetchQuery({
                  queryKey: ["currentProject", clientID, projectID],
                  queryFn: () => fetchCurrentProject(clientID, projectID),
                });
                let slideNumber = +params.row.recentSlide;
                navigate(
                  `/dashboard/form/${clientID}/${projectID}/${slideNumber}`,
                  { replace: true },
                );
              }}
            >
              View
            </Button>
          );
        } else if (params.value && reqStatus === "I") {
          // Render a button with the label 'Edit' for rows with requestStatus' equal to 'IP'
          return (
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={"editButton"}
              id={"editButton"}
              onClick={async () => {
                //get the id of the row and set value to currentProject in redux store and navigate to '/form'
                const projectID = params.value;
                const clientID = params.row.clientId;
                dispatch(setProjectID(projectID));

                // await queryClient.invalidateQueries({
                //   queryKey: ["currentProject", clientID, projectID],
                //   refetchType: "all",
                // });

                // await queryClient.prefetchQuery({
                //   queryKey: ["currentProject", clientID, projectID],
                //   queryFn: () => fetchCurrentProject(clientID, projectID),
                // });

                let slideNumber = +params.row.recentSlide;
                navigate(
                  `/dashboard/form/${clientID}/${projectID}/${slideNumber}`,
                  { replace: true },
                );
              }}
            >
              Edit
            </Button>
          );
        } else if (params.row.requestStatus === "C") {
          return (
            <Button
              variant="contained"
              color="primary"
              size="small"
              id={"downloadButton"}
              className={"downloadButton"}
              onClick={async () => {
                //get the questionnaire file and open it in a new tab
                setProjectID(params.value);
                let questionnaire = await queryClient.ensureQueryData({
                  queryKey: [
                    "questionnaire",
                    params.row.clientId,
                    params.value,
                  ],
                  queryFn: () =>
                    fetchQuestionnaire(params.row.clientId, params.value),
                });

                if (questionnaire) {
                  window.open(questionnaire.url, "_blank");
                }
              }}
            >
              Download
            </Button>
          );
        }
      },
    },
    {
      field: "fileDetails",
      headerName: "Files",
      flex: 0.75,
      hideable: true,
      pinnable: false,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      headerClassName: defaultStyles.fileDetailsHeader,
      cellClassName: defaultStyles.fileDetailsCells,
      valueGetter: (params) => {
        if (!params.value || params.value.length === 0) {
          return undefined;
        }
        return params.value;
      },
      renderCell: (params) => {
        if (!params.value) return;

        const [clientId, projectId] = params.value;

        const requiredFilesCount = params.row.requiredFilesCount;

        if (clientId && projectId) {
          return (
            <Tooltip
              title={
                requiredFilesCount > 0
                  ? `${requiredFilesCount} files have been marked as required and must be uploaded to fully complete request. Click to view files.`
                  : "Click to view files"
              }
              id="files-tooltip"
              placement="top"
              classes={{
                tooltip: "dashboard-tooltip",
                popper: "dashboard-tooltip-popper",
                arrow: "dashboard-arrow",
              }}
            >
              <IconButton
                aria-label="project-files-btn"
                variant="contained"
                size="small"
                id={"fileDetailsBtn"}
                className={"fileDetailsBtn"}
                onClick={async () => {
                  dispatch(setClientID(clientId));
                  dispatch(setProjectID(projectId));

                  await queryClient.prefetchQuery({
                    queryKey: ["file-structure", "project", projectId],
                    queryFn: async () => {
                      const res = await axios.get(
                        `/api/file-structure/project/${projectId}/all/`,
                        {
                          withCredentials: true,
                        },
                      );

                      return res.data;
                    },
                  });

                  navigate(`/dashboard/files/${clientId}/${projectId}`);
                }}
              >
                <Badge
                  badgeContent={requiredFilesCount}
                  variant={`${window.innerWidth < 1630 ? "dot" : "standard"}`}
                  color="rgb(198, 42, 42)"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  className={defaultStyles.filesBadgeCount}
                  classes={{
                    root: defaultStyles.filesBadgeRoot,
                    badge: defaultStyles.filesBadge,
                    dot: defaultStyles.filesBadgeDot,
                  }}
                >
                  <FolderIcon id={"folderIcon"} />
                </Badge>
              </IconButton>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "savedAt",
      type: "string",
      headerName: "",
      pinned: false,
      hideable: true,
      width: 0,
      minWidth: 0,
      resizable: false,
      flex: 0,
      sortable: false,
      disableColumnMenu: true,
    },
  ];
}
